import React from "react"
import SEO from "../components/seo"
import ImgIV from "../images/hiw.jpg"
import OneSvg from "../images/one.svg"
import TwoSvg from "../images/two.svg"
import ThreeSvg from "../images/three.svg"
import FourSvg from "../images/four.svg"
import FiveSvg from "../images/five.svg"

const ImageIV = () => <><img className='hiw-img with-shadow' src={ImgIV} alt='Girl with a flower' style={{ borderRadius: 16 }} /></>
const BulletOne = () => <><img className='bullets' src={OneSvg} alt='numeber one' /></>
const BulletTwo = () => <><img className='bullets' src={TwoSvg} alt='numeber two' /></>
const BulletThree = () => <><img className='bullets' src={ThreeSvg} alt='numeber three' /></>
const BulletFour = () => <><img className='bullets' src={FourSvg} alt='numeber four' /></>
const BulletFive = () => <><img className='bullets' src={FiveSvg} alt='numeber five' /></>


const Expectations = () => (

    <>
        <SEO title="What to Expect" />
        <div className='hero-section in-pages-bg'>
            <div className="cover-overlay"></div>
            <div className="overlay-text">
                <h2 className="is-white font-size-42 font-weight-bold">What to expect</h2>
                <p className="is-white font-size-20">Here is our simple five step process...</p>
            </div>
        </div>
        <section className="section-theming bg-white no--top-padding" id="what-to-expect">
        <div className="hiw-img-device mr-3"></div>
            {/* <div className="how-it-works d-lg-flex align-items-center justify-content-center d-none">
                
            </div> */}
            <div style={{ position: 'relative' }}>
                <ImageIV />
            </div>
            <div className="bg-gray">
                <div className="container wide-80 no-gutter-left--desktop">
                    <div className="row">
                        <div className="col-sm-5">
                            <div className="p-4 d-flex align-items-center bullet-alignment">
                                <BulletOne />
                                <h2 className="is-purple p-4 header-allignment hiw-ml-neg">Consultation</h2>
                            </div>
                        </div>
                        <div className="col-sm mr-4">
                            <p className="is-purple p-4">
                                Everything starts with a phone or in-office consultation with one of our friendly and highly trained medical staff.
                                During the consultation, we will learn about your symptoms and medical history to accurately determine if you are a
                                candidate for ketamine IV therapy. We know you will have questions too, so we will answer any questions you may have
                                about ketamine treatment right then and there.
                            </p>
                        </div>
                    </div>
                    <div className="row p-4-desktop">
                        <div className="col-sm-5">
                            <div className="p-4 d-flex align-items-center bullet-alignment">
                                <BulletTwo />
                                <h2 className="is-purple p-4 header-allignment hiw-ml-neg">Treatment <br /> Day</h2>
                            </div>
                        </div>
                        <div className="col-sm">
                            <p className="is-purple p-4">
                                Upon arrival, you will check in and then be taken back to the private infusion room to begin treatment.
                                You will be comfortably reclining or laying down for the 45 minute duration of the infusion and you will
                                wear a blood pressure cuff and other standard monitors.
                            </p>
                        </div>
                    </div>
                    <div className="row p-4-desktop">
                        <div className="col-sm-5">
                            <div className="p-4 d-flex align-items-center bullet-alignment">
                                <BulletThree />
                                <h2 className="is-purple p-4 header-allignment hiw-ml-neg">During <br /> Treatment</h2>
                            </div>
                        </div>
                        <div className="col-sm">
                            <p className="is-purple p-4">
                                After the infusion starts, you will almost immediately feel a little drowsy and will start feeling “free” or “floating”.
                                You will be comfortably reclining or laying down for the 45 minute duration of the infusion and you will wear a blood pressure
                                cuff and other standard monitors.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container wide-80 no-gutter-left--desktop">
                <div className="row p-4-desktop">
                    <div className="col-sm-5">
                        <div className="p-4 d-flex align-items-center bullet-alignment">
                            <BulletFour />
                            <h2 className="is-purple p-4 header-allignment hiw-ml-neg">After <br /> Treatment</h2>
                        </div>
                    </div>
                    <div className="col-sm">
                        <p className="is-purple p-4">
                            Once the infusion is over, your recovery will be around 30-45 minutes.  Anticipate drowsiness for a few hours after
                            the infusion. We strongly recommend that you pre-arrange a ride home after your treatment.
                        </p>
                    </div>
                </div>
                <div className="row p-4-desktop">
                    <div className="col-sm-5">
                        <div className="p-4 d-flex align-items-center bullet-alignment">
                            <BulletFive />
                            <h2 className="is-purple p-4 header-allignment hiw-ml-neg">Results <br /> of Infusion</h2>
                        </div>
                    </div>
                    <div className="col-sm">
                        <p className="is-purple p-4">
                            Patient&rsquo;s length of response to the infusion will vary from patient to patient but will increase with subsequent treatments.
                            For example, after the first series of six infusions for depression, you may experience relief of symptoms for 1-2
                            months and for up to  6 months.  Then as needed, we will schedule single booster infusions to control your symptoms.
                         </p>
                    </div>
                </div>
            </div>
        </section>

    </>
)

export default Expectations
